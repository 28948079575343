import React from 'react';
import PropTypes from 'prop-types';
import { Result, Button, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import PageHelmet from 'components/PageHelmet';

/**
 * Returns a react component show that the requested route was Not Found(404).
 *
 * @function
 * @params {Object} props - the component properties
 * @return {Object} a Result component showing a status of 404
 */
const NoMatch = ({ history }) => (
  <div className="container-fluid d-flex vh-100 align-items-center justify-content-center">
    <PageHelmet pageTitle="Page Not Found" />
    <Result
      status="404"
      title={(
        <Typography.Title level={1}>
          Page Not Found
        </Typography.Title>
      )}
      subTitle="Sorry, the page you are on currently does not exist."
      extra={(
        <Button
          type="primary"
          size="large"
          onClick={() => history.push('/')}
        >
          Dashboard
        </Button>
      )}
    />
  </div>
);

NoMatch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(NoMatch);
