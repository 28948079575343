import {
  all,
  call,
  put,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';
import RequestClient from 'config/RequestClient';
import localForage from 'localforage';
import { PATHS, API_ROUTES } from 'config/Router/constants';
import * as TYPES from './actionTypes';
import { signInUserSuccess, signInError } from './actions';

// const { store } = appStore;

/**
 * @function
 * Handles the signIn Process
 *
 * @param {Object} action - contains all required for the sign in process
 */
function* signInProcess(action) {
  const {
    enqueueSnackbar, history, values, setSubmitStatus,
  } = action.payload;

  try {
    const requestBody = {
      password: values.password,
      username: values.username,
    };

    const response = yield call(RequestClient.post, API_ROUTES.SIGN_IN, requestBody);

    if (response) {
      setSubmitStatus(false);
    }

    if (response.status === 200) {
      const processedData = {
        authorizations: response.data.data.ACL,
        isAuthenticated: true,
        isLoggedIn: true,
        token: response.headers['x-access-token'],
        user: response.data.data,
      };

      enqueueSnackbar('Sign In successful. Loading your dashboard', {
        autoHideDuration: 2500,
        key: 'loginStatus',
        variant: 'success',
      });

      yield put(signInUserSuccess(processedData));
      history.push(PATHS.DASHBOARD);
    } else {
      enqueueSnackbar('You have supplied an invalid username or password', {
        autoHideDuration: 2500,
        variant: 'error',
      });
    }
  } catch (error) {
    if (error) {
      setSubmitStatus(false);
    }

    if (error.toString() === 'Error: Network Error') {
      enqueueSnackbar('Please check your network connection and try again', {
        autoHideDuration: 3000,
        variant: 'error',
      });
      yield put(signInError(error));
    } else {
      enqueueSnackbar('You have supplied an invalid username or password', {
        autoHideDuration: 2500,
        variant: 'error',
      });
    }
  }
}

/**
 * @function
 * Handles the signIn Process
 *
 * @param {Object} action - contains all required for the sign out process
 */
function* signOutProcess(action) {
  const { enqueueSnackbar, history } = action.payload;
  try {
    yield put({
      state: {
        _persist: {
          rehydrated: true,
          version: -1,
        },
      },
      type: 'RESET',
    });
    yield history.push(PATHS.SIGN_IN);
    enqueueSnackbar('You have been logged out.', {
      key: 'logoutStatus',
      variant: 'success',
    });
    localForage.clear();
  } catch (error) {
    enqueueSnackbar(error.toString(), {
      autoHideDuration: 2500,
      variant: 'error',
    });
  }
}

/**
 * ----------------- Watchers -----------------
 */

/**
 * @function
 * Watches for the {@link TYPES.SIGN_IN SIGN_IN} action.
 * Triggers request to sign in an admin user.
 *
 * @return {void}
 */
function* watchSignInProcess() {
  try {
    yield takeLeading(TYPES.SIGN_IN, signInProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.SIGN_OUT SIGN_OUT} action.
 * Triggers request to sign out an admin user.
 *
 * @return {void}
 */
function* watchSignOutProcess() {
  try {
    yield takeLatest(TYPES.SIGN_OUT, signOutProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* () {
  yield all([
    watchSignInProcess(),
    watchSignOutProcess(),
  ]);
}
