import {
  all, call, put, takeLeading, select
} from 'redux-saga/effects';
import RequestClient from 'config/RequestClient';
import { tokenSelector, isTokenValidSelector } from 'pages/signIn/selectors';
import { isEqual, isNull } from 'lodash';
import { API_ROUTES } from 'config/Router/constants';
import { signOutUser } from 'pages/signIn/actions';
import * as TYPES from './actionTypes';
import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersError,
  fetchUserPagesSuccess,
  fetchTotalRevenueSuccess,
  appError,
  fetchPagesCountSuccess,
  fetchLeadsCountSuccess,
  fetchWalletSuccess,
  fetchTransactionsSuccess,
  fetchLeadsSuccess,
  fetchPaymentMethodsSuccess,
  fetchUserSubscriptionsSuccess,
  fetchUserAffiliatesSuccess,
  updateUserSubscriptionSuccess,
  setDashboardCount,
  updateUserVerificationStatusSuccess,
  fetchWalletBalanceSuccess,
  fetchUserByCategorySuccess,
  fetchPagesStatsSuccess,
  fetchShopsStatsSuccess
} from './actions';
import { usersListSelector, searchValueSelector, searchFilterValueSelector, sortUsersSelector } from './selectors';

/**
 * Helper function to get a single user entity
 *
 * @param {String} username
 */
function* fetchUserEntities(username, route, entity) {
  const token = yield select(tokenSelector);
  try {
    const headers = { Authorization: `Bearer ${token}` };

    const response = yield call(RequestClient.get, `${route}${username}`, {
      headers,
    });

    if (response.status === 200) {
      return { [entity]: response.data.data, username };
    }
    return { [entity]: null, username };
  } catch (error) {
    return { [entity]: null, username };
  }
}

/**
 * @function
 * Fetches all users
 *
 * @param {Object} action - contains all required to get all users
 */
function* fetchUsersProcess(action) {
  const { enqueueSnackbar, history, params } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);
  const existingUsers = yield select(usersListSelector);

  
  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      //filter and searches
      const query = yield select(searchValueSelector)
      const isSubscribed = yield select(searchFilterValueSelector)

      const sortBy = yield select(sortUsersSelector)

      if(query.length){
        params.query = query
      }
      if(!isNull(isSubscribed)){
        params.isSubscribed = isSubscribed
      }

      if(sortBy?.length){
        params.sort = sortBy
      }

      // Get all users
      const response = yield call(RequestClient.get, API_ROUTES.GET_USERS, {
        headers,
        params
      });

      // If users found
      if (response.status === 200) {
       const users = response.data.data;

        // If existing data is not equal to incoming data, then update the list
        if (!isEqual(existingUsers, users)) {
          yield put(fetchUsersSuccess(users));

          enqueueSnackbar('List of users updated.', {
            variant: 'success',
          });
        }
      } else {
        yield put(fetchUsersSuccess([]));
        enqueueSnackbar('Users list could not be fetched.', {
          variant: 'error',
        });
      }
    } catch (error) {
      console.log(error)
      yield put(fetchUsersError(error));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'An error has occured. All users could not be fetched',
          {
            variant: 'error',
          }
        );
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches a users pages
 *
 * @param {Object} action - contains all required to get all pages
 */
function* fetchPagesProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get all users
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_PAGES}${username}`,
        {
          headers,
        }
      );

      // If users found
      if (response.status === 200) {
        const pages = response.data.data;
        yield put(fetchUserPagesSuccess({ [username]: pages }));
      }
    } catch (error) {
      yield put(fetchUsersError(error));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'An error has occured. All pages could not be fetched',
          {
            variant: 'error',
          }
        );
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches all counts
 *
 * @param {Object} action - contains all required to get all pages
 */
function* fetchDashboardCountsProcess(action) {
  const { enqueueSnackbar, history } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      //Get Membership counts
      const membershipResponse = yield call(
        RequestClient.get,
        API_ROUTES.GET_MEMBERSHIP_COUNT,
          {
            headers,
          }
        );

      //Get OCP counts
      const ocpResponse = yield call(
        RequestClient.get,
        API_ROUTES.GET_OCP_COUNT,
        {
          headers,
        }
      );

         //Get Product counts
         const productResponse = yield call(
          RequestClient.get,
          API_ROUTES.GET_PRODUCT_COUNT,
          {
            headers,
          }
        );

      // Get total revenue
      const revenueResponse = yield call(
        RequestClient.get,
        API_ROUTES.GET_TOTAL_REVENUE,
        {
          headers,
        }
      );

      // Get pages count
      const pagesResponse = yield call(
        RequestClient.get,
        API_ROUTES.COUNT_ALL_PAGES,
        {
          headers,
        }
      );

      // Get leads count
      const leadsResponse = yield call(
        RequestClient.get,
        API_ROUTES.COUNT_ALL_LEADS,
        {
          headers,
        }
      );


      // Get shop count
      const shopResponse = yield call(
        RequestClient.get,
        API_ROUTES.GET_SHOP_COUNT,
        {
          headers,
        }
      );

      // Get email count
      const emailResponse = yield call(
        RequestClient.get,
        API_ROUTES.GET_EMAIL_COUNT,
        {
          headers,
        }
      );

      // If total email found
      if (emailResponse.status === 200) {
        const email = emailResponse.data.data;
        yield put(setDashboardCount({key:'emailCount', value: email?.count}));
      }

      // If total shop found
      if (shopResponse.status === 200) {
        const shop = shopResponse.data.data;
        yield put(setDashboardCount({key:'shopCount', value: shop?.count}));
      }

      // If total membership found
        if (membershipResponse.status === 200) {
          const membership = membershipResponse.data.data;
          yield put(setDashboardCount({key:'membershipCount', value: membership?.count}));
        }

      // If total product found
        if (productResponse.status === 200) {
          const product = productResponse.data.data;
          yield put(setDashboardCount({key:'digitalProductsCount', value: product?.digitalProductCount}));
          yield put(setDashboardCount({key:'physicalProductsCount', value: product?.physicalProductCount}));
        }

        // If total ocp found
        if (ocpResponse.status === 200) {
          const ocp = ocpResponse.data.data;
          yield put(setDashboardCount({key:'ocpCount', value: ocp?.count}));
        }

      // If total revenue found
      if (revenueResponse.status === 200) {
        const revenue = revenueResponse.data.data;
        yield put(fetchTotalRevenueSuccess(revenue));
      }

      // If pages found
      if (pagesResponse.status === 200) {
        const pages = pagesResponse.data.data;
        yield put(fetchPagesCountSuccess(pages));
      }

      // If leads found
      if (leadsResponse.status === 200) {
        const leads = leadsResponse.data.data;
        yield put(fetchLeadsCountSuccess(leads));
      }
    } catch (error) {
      yield put(appError({ FETCH_DASHBOARD_COUNTS: error.message }));
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches users wallet
 *
 * @param {Object} action - contains all required to get wallet information
 */
function* fetchWalletProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users wallet
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_WALLET}${username}`,
        {
          headers,
        }
      );

      // If users found
      if (response.status === 200) {
        const wallet = response.data.data;
        yield put(fetchWalletSuccess({ [username]: wallet }));
      }
    } catch (error) {
      yield put(appError({ FETCH_USER_WALLET: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'Wallet data could not be fetched for this user',
          {
            variant: 'info',
          }
        );
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}


/**
 * @function
 * Fetches users wallet
 *
 * @param {Object} action - contains all required to get wallet information
 */
 function* fetchWalletBalanceProcess(action) {
  const { enqueueSnackbar, history, currency } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users wallet
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_WALLET_BALANCE}`,
        {
          headers,
          params:{
            curr: currency
          }
        }
      );

      if (response.status === 200) {
        const d = response.data.data;
        yield put(fetchWalletBalanceSuccess(d.balance));
      }
    } catch (error) {
      yield put(appError({ FETCH_WALLET_BALANCE: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'Wallet Balance data could not be fetched',
          {
            variant: 'info',
          }
        );
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}


/**
 * @function
 * Fetches users wallet
 *
 * @param {Object} action - contains all required to get wallet information
 */
 function* fetchUsersByCategoryProcess(action) {
  const { enqueueSnackbar, history, category } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users wallet
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_BY_CATEGORY}${category}`,
        {
          headers,
        }
      );
        console.log(response)
      if (response.status === 200) {
        const d = response.data.data;
        yield put(fetchUserByCategorySuccess(d.count));
      }
    } catch (error) {
      yield put(appError({ FETCH_USER_BY_CATEGORY: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        yield put(fetchUserByCategorySuccess(0));
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches users transactions
 *
 * @param {Object} action - contains all required to get transactions information
 */
function* fetchTransactionsProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users transactions
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_TRANSACTIONS}${username}`,
        {
          headers,
        }
      );

      // If users found
      if (response.status === 200) {
        const transactions = response.data.data;
        yield put(fetchTransactionsSuccess({ [username]: transactions }));
      }
    } catch (error) {
      yield put(appError({ FETCH_USER_TRANSACTIONS: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'Transactions data could not be fetched for this user',
          {
            variant: 'info',
          }
        );
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches users leads
 *
 * @param {Object} action - contains all required to get leads information
 */
function* fetchLeadsProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users leads
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USERS_LEADS}${username}`,
        {
          headers,
        }
      );

      // If users found
      if (response.status === 200) {
        const leads = response.data.data;
        yield put(fetchLeadsSuccess({ [username]: leads }));
      }
    } catch (error) {
      yield put(appError({ FETCH_USER_LEADS: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Leads data could not be fetched', {
          variant: 'info',
        });
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * Helper function to update user subscription
 *
 * @param {String} username
 */
function* updateUserSubscriptionSaga(action) {
  const {payload, enqueueSnackbar, handleCancel, closeSnackbar, history} = action.payload
  enqueueSnackbar('Updating User',{
    variant: 'info',
    key: 'updatingUser'
  })
  const token = yield select(tokenSelector);
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const { data:{ message }} = yield call(  RequestClient.patch,
      API_ROUTES.UPDATE_USER_SUBSCRIPTION, payload,
      {
        headers,
      });
      closeSnackbar('updatingUser')
    enqueueSnackbar(message, {
      variant: 'success',
    });
    yield handleCancel()
    yield put(updateUserSubscriptionSuccess());
    yield put(fetchUsers({enqueueSnackbar,history,params:{}}));
  } catch (error) {
    closeSnackbar('updatingUser')
    yield put(appError({ UPDATE_USER_SUBSCRIPTION: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('User could not be updated', {
          variant: 'error',
        });
      }
    }
  }


/**
 * Helper function to update user subscription
 *
 * @param {String} username
 */
function* updateUserVerificationSaga(action) {
  const {payload, enqueueSnackbar, handleVerifyClose, closeSnackbar, history} = action.payload
  enqueueSnackbar('Updating User',{
    variant: 'info',
    key: 'updatingUser'
  })
  const token = yield select(tokenSelector);
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const { data:{ message }} = yield call(  RequestClient.put,
      `${API_ROUTES.UPDATE_USER_VERIFICATION_STATUS}${payload?.id}`, payload,
      {
        headers,
      });
      closeSnackbar('updatingUser')
    enqueueSnackbar(message, {
      variant: 'success',
    });
    yield handleVerifyClose()
    yield put(updateUserVerificationStatusSuccess());
    yield put(fetchUsers({enqueueSnackbar,history,params:{}}));
  } catch (error) {
    closeSnackbar('updatingUser')
    yield put(appError({ UPDATE_USER_VERIFICATION_STATUS: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('User could not be updated', {
          variant: 'error',
        });
      }
    }
  }


/**
 * @function
 * Fetches users payment methods
 *
 * @param {Object} action - contains all required to get user payment method
 */
function* fetchPaymentMethodsProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users payment methods
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_PAYMENT_METHODS}${username}`,
        {
          headers,
        }
      );

      // If found
      if (response.status === 200) {
        const paymentMethods = response.data.data;
        yield put(fetchPaymentMethodsSuccess({ [username]: paymentMethods }));
      }
    } catch (error) {
      yield put(appError({ FETCH_PAYMENT_METHODS: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Payment method data could not be fetched', {
          variant: 'info',
        });
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches users subscriptions
 *
 * @param {Object} action - contains all required to get users subscriptions
 */
function* fetchUserSubscriptionsProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users subscriptions
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_SUBSCRIPTIONS}${username}`,
        {
          headers,
        }
      );

      // If found
      if (response.status === 200) {
        const subscriptions = response.data.data;
        yield put(fetchUserSubscriptionsSuccess({ [username]: subscriptions }));
      }
    } catch (error) {
      yield put(appError({ FETCH_PAYMENT_METHODS: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Subscription data could not be fetched', {
          variant: 'info',
        });
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * @function
 * Fetches users affiliates
 *
 * @param {Object} action - contains all required to get users affiliates
 */
function* fetchUserAffiliatesProcess(action) {
  const { enqueueSnackbar, history, username } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users affiliates
      const response = yield call(
        RequestClient.get,
        `${API_ROUTES.GET_USER_AFFILIATES}${username}`,
        {
          headers,
        }
      );

      // If found
      if (response.status === 200) {
        const affiliates = response.data.data;
        yield put(fetchUserAffiliatesSuccess({ [username]: affiliates }));
      }
    } catch (error) {
      yield put(appError({ FETCH_USER_AFFILIATES: error.message }));
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Affiliates data could not be fetched', {
          variant: 'info',
        });
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

/**
 * ----------------- Watchers -----------------
 */

/**
 * @function
 * Watches for the {@link TYPES.FETCH_USERS FETCH_USERS} action.
 * Triggers request to fetch all users.
 *
 * @return {void}
 */
function* watchFetchUsersProcess() {
  try {
    yield takeLeading(TYPES.FETCH_USERS, fetchUsersProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_PAGES FETCH_PAGES} action.
 * Triggers request to fetch all pages.
 *
 * @return {void}
 */
function* watchFetchPagesProcess() {
  try {
    yield takeLeading(TYPES.FETCH_PAGES, fetchPagesProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_DASHBOARD_COUNTS FETCH_DASHBOARD_COUNTS} action.
 * Triggers request to fetch total revenue.
 *
 * @return {void}
 */
function* watchFetchDashboardCountsProcess() {
  try {
    yield takeLeading(
      TYPES.FETCH_DASHBOARD_COUNTS,
      fetchDashboardCountsProcess
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* watchUpdateUserSubscriptionSaga() {
  try {
    yield takeLeading(
      TYPES.UPDATE_USER_SUBSCRIPTION,
      updateUserSubscriptionSaga
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}


function* watchUpdateUserVerificationSaga() {
  try {
    yield takeLeading(
      TYPES.UPDATE_USER_VERIFICATION_STATUS,
      updateUserVerificationSaga
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}


/**
 * @function
 * Watches for the {@link TYPES.FETCH_WALLET FETCH_WALLET} action.
 * Triggers request to fetch user wallets.
 *
 * @return {void}
 */
function* watchFetchWalletProcess() {
  try {
    yield takeLeading(TYPES.FETCH_WALLET, fetchWalletProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_TRANSACTIONS FETCH_TRANSACTIONS} action.
 * Triggers request to fetch user transactions.
 *
 * @return {void}
 */
function* watchFetchTransactionsProcess() {
  try {
    yield takeLeading(TYPES.FETCH_TRANSACTIONS, fetchTransactionsProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_LEADS FETCH_LEADS} action.
 * Triggers request to fetch user leads.
 *
 * @return {void}
 */
function* watchFetchLeadsProcess() {
  try {
    yield takeLeading(TYPES.FETCH_LEADS, fetchLeadsProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_PAYMENT_METHODS FETCH_PAYMENT_METHODS} action.
 * Triggers request to fetch user payment method.
 *
 * @return {void}
 */
function* watchFetchPaymentMethodsProcess() {
  try {
    yield takeLeading(TYPES.FETCH_PAYMENT_METHODS, fetchPaymentMethodsProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_USER_SUBSCRIPTIONS FETCH_USER_SUBSCRIPTIONS} action.
 * Triggers request to fetch user subscriptions.
 *
 * @return {void}
 */
function* watchFetchUserSubscriptionsProcess() {
  try {
    yield takeLeading(TYPES.FETCH_USER_SUBSCRIPTIONS, fetchUserSubscriptionsProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_USER_AFFILIATES FETCH_USER_AFFILIATES} action.
 * Triggers request to fetch user affiliates.
 *
 * @return {void}
 */
function* watchFetchUserAffiliatesProcess() {
  try {
    yield takeLeading(TYPES.FETCH_USER_AFFILIATES, fetchUserAffiliatesProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}


/**
 * @function
 * Watches for the {@link TYPES.FETCH_USER_AFFILIATES FETCH_USER_AFFILIATES} action.
 * Triggers request to fetch user affiliates.
 *
 * @return {void}
 */
 function* watchFetchWalletBalanceProcess() {
  try {
    yield takeLeading(TYPES.FETCH_WALLET_BALANCE, fetchWalletBalanceProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.FETCH_USER_AFFILIATES FETCH_USER_AFFILIATES} action.
 * Triggers request to fetch user affiliates.
 *
 * @return {void}
 */
 function* watchFetchUserByCategoryProcess() {
  try {
    yield takeLeading(TYPES.FETCH_USER_BY_CATEGORY, fetchUsersByCategoryProcess);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}


function* fetchPagesStats(action) {
  const { enqueueSnackbar, history } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users wallet
      const response = yield call(
        RequestClient.get,
        `/pages/all/count`,
        {
          headers,
        }
      );
        console.log(response)
      if (response.status === 200) {
        const d = response.data.data;
       console.log('d', d);
       yield put(fetchPagesStatsSuccess(d))
      }
    } catch (error) {
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        console.log('error.response', error?.response, error?.message);
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

function* fetchShopsStats(action) {
  const { enqueueSnackbar, history } = action.payload;
  const token = yield select(tokenSelector);
  const isTokenValid = yield select(isTokenValidSelector);

  if (isTokenValid) {
    try {
      const headers = { Authorization: `Bearer ${token}` };

      // Get users wallet
      const response = yield call(
        RequestClient.get,
        `/shops/count/admin`,
        {
          headers,
        }
      );
        console.log(response)
      if (response.status === 200) {
        const d = response.data.data;
       console.log('d', d);
       yield put(fetchShopsStatsSuccess(d))
      }
    } catch (error) {
      if (error.toString() === 'Error: Network Error') {
        enqueueSnackbar('Please check your network connection and try again', {
          variant: 'error',
        });
      } else {
        console.log('error.response', error?.response, error?.message);
      }
    }
  } else {
    yield put(signOutUser({ enqueueSnackbar, history }));
  }
}

function* watchFetchPagesStats() {
  yield takeLeading(TYPES.FETCH_PAGES_STATS, fetchPagesStats);

}

function* watchFetchShopsStats() {
  yield takeLeading(TYPES.FETCH_SHOPS_STATS, fetchShopsStats);
}



export default function* () {
  yield all([
    watchFetchUsersProcess(),
    watchFetchPagesProcess(),
    watchFetchWalletBalanceProcess(),
    watchFetchUserByCategoryProcess(),
    watchFetchDashboardCountsProcess(),
    watchFetchWalletProcess(),
    watchFetchTransactionsProcess(),
    watchFetchLeadsProcess(),
    watchFetchPaymentMethodsProcess(),
    watchFetchUserSubscriptionsProcess(),
    watchFetchUserAffiliatesProcess(),
    watchUpdateUserSubscriptionSaga(),
    watchUpdateUserVerificationSaga(),
    watchFetchPagesStats(),
    watchFetchShopsStats()
  ]);
}
