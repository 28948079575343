import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { isUserAuthorizedSelector } from 'pages/signIn/selectors';
import { connect } from 'react-redux';
import { PATHS } from '../Router/constants';

const { SIGN_IN } = PATHS;

/**
 * Handles the authorization flow in the application.
 *
 * @function
 * @params {Object} Component - the child react component to be provided context
 * @return {Object} authorization handler
 */
const withAuthorization = () => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const { history, isUserAuthorized } = this.props;
      if (!isUserAuthorized) {
        history.push(SIGN_IN);
      }
    }

    render() {
      const { isUserAuthorized } = this.props;
      return isUserAuthorized ? <Component {...this.props} /> : <Redirect to={PATHS.SIGN_IN} />;
    }
  }

  WithAuthorization.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    isUserAuthorized: PropTypes.bool.isRequired,
  };

  const mapStateToProps = state => ({
    isUserAuthorized: isUserAuthorizedSelector(state),
  });

  return compose(
    connect(mapStateToProps, null),
    withRouter
  )(WithAuthorization);
};

export default withAuthorization;
