import React from 'react';
import {
  LogoutOutlined,
  LayoutOutlined,
  UserOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { signOutUser } from 'pages/signIn/actions';

export const COMPONENT = 'app';

export const MENU_ITEMS = [
  {
    href: '/dashboard',
    icon: <LayoutOutlined />,
    key: 'dashboard',
    text: 'Dashboard',
  },
  {
    href: '/users',
    icon: <UserOutlined />,
    key: 'users',
    text: 'Users',
  },
  {
    href: '/settings',
    icon: <SettingOutlined />,
    key: 'settings',
    text: 'Settings',
  },
];

export const DROPDOWN_ITEMS = [
  {
    icon: <LogoutOutlined />,
    key: '3',
    text: 'Log Out',
  },
];

export const HEADER_KEY_MAP = {
  '/dashboard': 'Dashboard',
  '/settings': 'Settings',
  '/users': 'Users',
};

export const HEADER_DROP_MENU = [
  {
    action: signOutUser, dispatchAction: true, icon: <LogoutOutlined />, key: 'logout', text: 'Log Out',
  },
];

export const SETTINGS = {
  DEFAULT_SELECTED_KEYS: ['/dashboard'],
  MODE: 'vertical',
  THEME: 'dark',
};
