import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { MENU_ITEMS, SETTINGS } from './constants';
import { ALLOWED_PATHS } from '../../config/Router/constants';
import Logo from '../../static/images/softlink-logo-white.svg';
import LogoIcon from '../../static/images/icon-dark.svg';

const { Item } = Menu;
const { MODE, THEME } = SETTINGS;

/**
 * The menu used to navigation through the application
 *
 * @function
 * @return {Object} the app level menu
 */
const AppMenu = props => {
  const {
    history: {
      location: { pathname },
    },
    menuTrigger,
    menuCollapsed,
  } = props;
  const ALLOWED_MENU = MENU_ITEMS.filter(item => ALLOWED_PATHS.includes(item.href));
  const selectedKey = pathname.split('/')[1];
  const screenHeight = window.screen.height;

  return (
    <Menu selectedKeys={[selectedKey]} mode={MODE} theme={THEME}>
      <Item
        title={screenHeight <= 767 ? 'Open Menu' : 'Softlink.ai'}
        className="logo-menu-item"
        onClick={screenHeight <= 767 && (() => menuTrigger())}
      >
        <img
          alt="Softlink AI"
          src={menuCollapsed ? LogoIcon : Logo}
          className="img-fluid"
        />
      </Item>
      {ALLOWED_MENU.map(item => {
        const {
          href, icon, key, text,
        } = item;
        return (
          <Item key={key}>
            <Link to={href}>
              {icon}
              <span>{text}</span>
            </Link>
          </Item>
        );
      })}
    </Menu>
  );
};

AppMenu.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
  menuCollapsed: PropTypes.bool.isRequired,
  menuTrigger: PropTypes.func.isRequired,
};

export default withRouter(AppMenu);
