import * as TYPES from './actionTypes';

export const fetchUsers = payload => ({
  payload,
  type: TYPES.FETCH_USERS,
});

export const fetchShopsStats = payload => ({
  payload,
  type: TYPES.FETCH_SHOPS_STATS,
})

export const fetchPagesStats = payload => ({
  payload,
  type: TYPES.FETCH_PAGES_STATS
})

export const fetchShopsStatsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_SHOPS_STATS_SUCCESS,
})

export const fetchPagesStatsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_PAGES_STATS_SUCCESS
})

export const fetchUsersSuccess = payload => ({
  payload,
  type: TYPES.FETCH_USERS_SUCCESS,
});

export const fetchUsersError = payload => ({
  payload,
  type: TYPES.FETCH_USERS_ERROR,
});

export const updateUser = payload => ({
  payload,
  type: TYPES.UPDATE_USER,
});

export const updateUserError = payload => ({
  payload,
  type: TYPES.UPDATE_USER_ERROR,
});

export const selectUser = payload => ({
  payload,
  type: TYPES.SELECT_SINGLE_USER,
});

export const searchValue = payload => ({
  payload,
  type: TYPES.SEARCH_VALUE,
});

export const setSortValue = payload => ({
  payload,
  type: TYPES.SORT_USERS,
});

export const searchDateValue = payload => ({
  payload,
  type: TYPES.SEARCH_DATE_VALUE,
});

export const searchSubscribedValue = payload => ({
  payload,
  type: TYPES.SEARCH_SUBSCRIBED_VALUE,
});

export const fetchUserPages = payload => ({
  payload,
  type: TYPES.FETCH_PAGES,
});

export const fetchUserPagesSuccess = payload => ({
  payload,
  type: TYPES.FETCH_PAGES_SUCCESS,
});

export const fetchDomainsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_DOMAINS_SUCCESS,
});

export const fetchWalletSuccess = payload => ({
  payload,
  type: TYPES.FETCH_WALLET_SUCCESS,
});

export const fetchWallet = payload => ({
  payload,
  type: TYPES.FETCH_WALLET,
});

export const fetchTransactionsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_TRANSACTIONS_SUCCESS,
});

export const fetchTransactions = payload => ({
  payload,
  type: TYPES.FETCH_TRANSACTIONS,
});

export const fetchLeadsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_LEADS_SUCCESS,
});

export const fetchLeads = payload => ({
  payload,
  type: TYPES.FETCH_LEADS,
});

export const fetchDashboardCounts = payload => ({
  payload,
  type: TYPES.FETCH_DASHBOARD_COUNTS,
});

export const fetchTotalRevenueSuccess = payload => ({
  payload,
  type: TYPES.FETCH_TOTAL_REVENUE_SUCCESS,
});

export const fetchPagesCountSuccess = payload => ({
  payload,
  type: TYPES.FETCH_PAGES_COUNT_SUCCESS,
});

export const fetchLeadsCountSuccess = payload => ({
  payload,
  type: TYPES.FETCH_LEADS_COUNT_SUCCESS,
});

export const appError = payload => ({
  payload,
  type: TYPES.APP_ERROR,
});

export const fetchPaymentMethodsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_PAYMENT_METHODS_SUCCESS,
});

export const fetchPaymentMethods = payload => ({
  payload,
  type: TYPES.FETCH_PAYMENT_METHODS,
});

export const fetchUserSubscriptionsSuccess = payload => ({
  payload,
  type: TYPES.FETCH_USER_SUBSCRIPTIONS_SUCCESS,
});

export const fetchUserSubscriptions = payload => ({
  payload,
  type: TYPES.FETCH_USER_SUBSCRIPTIONS,
});

export const fetchUserAffiliates = payload => ({
  payload,
  type: TYPES.FETCH_USER_AFFILIATES
})
export const fetchUserAffiliatesSuccess = payload => ({
  payload, 
  type: TYPES.FETCH_USER_AFFILIATES_SUCCESS
})

export const updateUserSubscription = payload => ({
  payload,
  type: TYPES.UPDATE_USER_SUBSCRIPTION
})

export const updateUserSubscriptionSuccess = payload => ({
  payload,
  type: TYPES.UPDATE_USER_SUBSCRIPTION_SUCCESS
})

export const setDashboardCount = payload => ({
  payload,
  type: TYPES.SET_DASHBOARD_COUNT
})


export const updateUserVerificationStatus = payload => ({
  payload,
  type: TYPES.UPDATE_USER_VERIFICATION_STATUS
})

export const updateUserVerificationStatusSuccess = payload => ({
  payload,
  type: TYPES.UPDATE_USER_VERIFICATION_STATUS_SUCCESS
})

export const fetchWalletBalanceSuccess = payload => ({
  payload,
  type: TYPES.FETCH_WALLET_BALANCE_SUCCESS,
});

export const fetchWalletBalance = payload => ({
  payload,
  type: TYPES.FETCH_WALLET_BALANCE,
});

export const fetchUserByCategorySuccess = payload => ({
  payload,
  type: TYPES.FETCH_USER_BY_CATEGORY_SUCCESS,
});

export const fetchUserByCategory = payload => ({
  payload,
  type: TYPES.FETCH_USER_BY_CATEGORY,
});
