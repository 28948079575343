import React from 'react';
import ReactDOM from 'react-dom';
import './global.scss';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import appStore from 'config/store';
import { PersistGate } from 'redux-persist/integration/react';
import PageLoader from 'components/PageLoader';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { store, persistor } = appStore;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <SnackbarProvider
          maxSnack={3}
          dense
          disableWindowBlurListener
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          preventDuplicate
        >
          <App />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
