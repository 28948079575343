import { COMPONENT } from './constants';

export const SIGN_IN = `${COMPONENT}/SIGN_IN`;

export const SIGN_IN_ERROR = `${COMPONENT}/SIGN_IN_ERROR`;

export const SIGN_IN_SUCCESS = `${COMPONENT}/SIGN_IN_SUCCESS`;

export const SIGN_OUT = `${COMPONENT}/SIGN_OUT`;

export const SIGN_OUT_SUCCESS = `${COMPONENT}/SIGN_OUT_SUCCESS`;
