import * as TYPES from './actionTypes';

const initialState = {
  adminUsers: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case TYPES.FETCH_ALL_ADMIN_USERS_SUCCESS:
    return { ...state, adminUsers: payload || [] };

  default:
    return state;
  }
};
