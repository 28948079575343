import { createSelector } from 'reselect';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { COMPONENT } from './constants';

export const authUserSelector = state => state[COMPONENT].user;

export const tokenSelector = state => state[COMPONENT].token;

export const authUserAuthorizationsSelector = state => state[COMPONENT].authorizations;

export const isUserAuthorizedSelector = createSelector(
  state => state[COMPONENT].isAuthenticated,
  state => state[COMPONENT].isLoggedIn,
  (isAuthenticated, isLoggedIn) => {
    if (isAuthenticated && isLoggedIn) {
      return true;
    }

    return false;
  }
);

export const isTokenValidSelector = state => {
  const token = tokenSelector(state);
  const { exp } = jwt_decode(token);
  const todaysDate = moment().format('LLL')
  const expiryDate = moment.unix(exp).format('LLL')
  
  if (moment(todaysDate).isAfter(expiryDate)) {
    return false;
  }

  return true;
};
