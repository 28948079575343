import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tag, Dropdown, Menu, Tooltip } from "antd";
import { EllipsisOutlined, InfoCircleOutlined } from "@ant-design/icons";

export const COMPONENT = "app";

export const generateUsersStats = (users, totalUsers, actions) => {
  const usersToday = users?.filter((user) => moment().isSame(user.createdAt));
  const CURRENCY = ["NGN", "USD", "GBP", "ZAR", "GHS", "KES", "TZS", "UGX"];

  const USER_TYPES = [
    {
      label: "Coach",
      value: "coach",
    },
    {
      label: "Consultant",
      value: "consultant",
    },
    {
      label: "Digital Marketer",
      value: "digitalMarketer",
    },
    {
      label: "Newtork Marketer",
      value: "networkMarketer",
    },
    {
      label: "Affiliate Marketer",
      value: "affiliateMarketer",
    },
    {
      label: "Business Owner",
      value: "businessOwner",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  const userCategory = USER_TYPES.find(
    ({ value }) => value === actions.userCategory,
  ).label;

  return [
    {
      currency: false,
      currentValue: new Intl.NumberFormat("en-Gb").format(
        usersToday?.length || 0,
      ),
      key: "ActiveUsers",
      percentage: 0,
      showCurrentValue: false,
      title: "Activated Users",
      totalValue: parseInt(totalUsers.activated || 0),
      info: (
        <Tooltip
          placement="top"
          title="Activated users are users with at least one published page"
        >
          <InfoCircleOutlined />
        </Tooltip>
      ),
    },
    {
      currency: false,
      currentValue: new Intl.NumberFormat("en-Gb").format(
        usersToday?.length | 0,
      ),
      key: "TotalUsers",
      percentage: 0,
      showCurrentValue: true,
      title: "Total Users",
      totalValue: parseInt(totalUsers.total || 0),
    },
    {
      currency: true,
      currentValue: new Intl.NumberFormat("en-Gb").format(
        usersToday?.length || 0,
      ),
      key: "WalletBalance",
      percentage: 0,
      showDropdown: true,
      showCurrentValue: false,
      title: "Wallet Balance",
      menu: (
        <Menu>
          {CURRENCY.map((v) => (
            <Menu.Item key={v}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  actions.setCurrency(v);
                }}
                href="/"
              >
                {v}
              </a>
            </Menu.Item>
          ))}
        </Menu>
      ),
      totalValue: parseInt(actions.totalWalletBalance || 0),
      dropdownValue: actions.currency,
    },
    {
      currency: false,
      currentValue: new Intl.NumberFormat("en-Gb").format(
        usersToday?.length || 0,
      ),
      key: "TotalUsersByCategory",
      percentage: 0,
      showDropdown: true,
      showCurrentValue: false,
      title: `Total ${userCategory}`,
      menu: (
        <Menu>
          {USER_TYPES.map(({ label, value }) => (
            <Menu.Item key={value}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  actions.setUserCategory(value);
                }}
                href="/"
              >
                {label}
              </a>
            </Menu.Item>
          ))}
        </Menu>
      ),
      totalValue: parseInt(actions.totalUserByCategory || 0),
      dropdownValue: userCategory,
    },
  ];
};

export const MONTHS_OF_THE_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const generateColumns = (
  setOpen,
  setSelectedUser,
  dynamicCol = null,
  setVerifyOpen,
) => {
  const defaultCol = [
    {
      align: "left",
      ellipsis: true,
      fixed: "left",
      key: "name",
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <Link
          to={`users/view/${record.username}_${record._id}`}
          style={{ color: "#0F61D9" }}
          target="_blank"
        >
          {`${record.firstName} ${record.lastName}`}
        </Link>
      ),
      title: "Name",
      width: 150,
    },
    {
      dataIndex: "username",
      key: "username",
      title: "Username",
      width: 130,
    },
    {
      dataIndex: "email",
      ellipsis: true,
      key: "email",
      title: "Email",
      width: 190,
    },
    {
      dataIndex: "phoneNumber",
      ellipsis: true,
      key: "phoneNumber",
      title: "Phone Number",
      width: 190,
    },
    {
      align: "center",
      dataIndex: ["referral", "level"],
      ellipsis: true,
      key: "referral",
      title: "Referral Level",
      width: 130,
    },
    {
      align: "center",
      dataIndex: "isSubscribed",
      ellipsis: true,
      key: "isSubscribed",
      // eslint-disable-next-line react/display-name
      render: (isSubscribed) => {
        if (isSubscribed) {
          return <Tag color="#42C169">Yes</Tag>;
        }

        return <Tag color="#C7483E">No</Tag>;
      },
      title: "Subscribed",
      width: 100,
    },
    {
      align: "center",
      dataIndex: "status",
      ellipsis: true,
      key: "status",
      // eslint-disable-next-line react/display-name
      render: (status) => {
        if (status === "active") {
          return <Tag color="#42C169">Active</Tag>;
        }

        return <Tag color="#C7483E">Inactive</Tag>;
      },
      title: "Status",
      width: 100,
    },
    {
      align: "center",
      dataIndex: "isVerified",
      ellipsis: true,
      key: "Verification Status",
      // eslint-disable-next-line react/display-name
      render: (status) => {
        if (status) {
          return <Tag color="#42C169">Verified</Tag>;
        }

        return <Tag color="#C7483E">Unverified</Tag>;
      },
      title: "Verification Status",
      width: 100,
    },
    {
      align: "right",
      dataIndex: "createdAt",
      ellipsis: true,
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("LL"),
      title: "Date Joined",
      width: 180,
    },
    {
      align: "center",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        const handleClick = (e) => {
          e.preventDefault();
          setSelectedUser(record.username);
          setOpen(true);
        };

        const handleVerification = (e) => {
          e.preventDefault();
          setSelectedUser(record);
          setVerifyOpen(true);
        };

        const menu = (
          <Menu>
            <Menu.Item>
              <a href="/" onClick={handleClick}>
                Update Subscription
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href="/" onClick={handleVerification}>
                {record?.isVerified ? "Unverify User" : "Verify User"}
              </a>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown trigger={["click", "hover"]} overlay={menu}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
      width: 50,
    },
  ];

  return dynamicCol
    ? [defaultCol.shift(), dynamicCol, ...defaultCol]
    : defaultCol;
};

export const DATE_OPTIONS = [
  { key: "allEntries", text: "Show all entries", value: "-1" },
  { key: "1Days", text: "Today", value: "1" },
  { key: "10Days", text: "Last 10 Days", value: "10" },
  { key: "30Days", text: "Last 30 Days", value: "30" },
  { key: "60Days", text: "Last 60 Days", value: "60" },
  { key: "90Days", text: "Last 90 Days", value: "90" },
];

export const SUBSCRIBED_OPTIONS = [
  { key: "allUsers", text: "Show all", value: "all" },
  { key: "subscribed", text: "Subscribed", value: "true" },
  { key: "notSubscribed", text: "Not Subscribed", value: "false" },
];

export const PLAN = ["monthly", "yearly", "biennial"];

export const SUBSCRIPTION_CURRENCY = [
  { name: "Nigerian Naira", code: "NGN" },
  { name: "United State Dollars", code: "USD" },
  { name: "British Pound Sterling", code: "GBP" },
  { name: "Ghanian Cedi", code: "GHS" },
];
