import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import signIn from "./pages/signIn";
import NoMatch from "./pages/noMatch";
import { PATHS, ALLOWED_PATHS } from "./config/Router/constants";
import AppLayout from "./components/AppLayout";

const { SignIn } = signIn;

const App = () => (
  <Router>
    {/* <Suspense fallback={<p>loading...</p>}> */}
      <Switch>
        <Redirect exact from={PATHS.HOME} to={PATHS.DASHBOARD} />
        <Route exact path={PATHS.SIGN_IN} render={() => <SignIn />} />
        <Route exact path={ALLOWED_PATHS} render={() => <AppLayout />} />
        <Route component={NoMatch} />
      </Switch>
    {/* </Suspense> */}
  </Router>
);

export default App;
