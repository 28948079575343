import * as TYPES from "./actionTypes";

const initialState = {
  dateSearch: false,
  domains: [],
  errors: false,
  leads: {},
  leadsCount: "",
  pages: {},
  pagesCount: "",
  paymentMethods: {},
  subscriptions: {},
  search: false,
  selectedUser: false,
  subscribedSearch: false,
  totalRevenue: 0,
  transactions: {},
  users: [],
  wallets: {},
  affiliates: {},
  shopCount: "",
  membershipCount: "",
  digitalProductsCount: "",
  physicalProductsCount: "",
  ocpCount: "",
  emailCount: "",
  walletBalanceCount: "",
  userByCategoryCount: "",
  pagesStats: null,
  shopStats: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_PAGES_STATS_SUCCESS:
      return { ...state, pagesStats: payload };

    case TYPES.FETCH_SHOPS_STATS_SUCCESS:
      return { ...state, shopStats: payload };

    case TYPES.FETCH_USERS_SUCCESS:
      return { ...state, users: payload };

    case TYPES.FETCH_USERS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, FETCH_USERS_ERROR: payload },
      };

    case TYPES.SELECT_SINGLE_USER:
      return { ...state, selectedUser: payload };

    case TYPES.UPDATE_USER_ERROR:
      return {
        ...state,
        errors: { ...state.errors, UPDATE_USER_ERROR: payload },
      };

    case TYPES.APP_ERROR:
      return { ...state, errors: { ...state.errors, ...payload } };

    case TYPES.SEARCH_VALUE:
      return { ...state, search: payload };

    case TYPES.SORT_USERS:
      return { ...state, sortUsers: payload };

    case TYPES.SEARCH_DATE_VALUE:
      return { ...state, dateSearch: payload };

    case TYPES.SEARCH_SUBSCRIBED_VALUE:
      return { ...state, subscribedSearch: payload };

    case TYPES.FETCH_PAGES_SUCCESS:
      return { ...state, pages: { ...state.pages, ...payload } };

    case TYPES.FETCH_DOMAINS_SUCCESS:
      return { ...state, domains: payload };

    case TYPES.FETCH_WALLET_SUCCESS:
      return { ...state, wallets: { ...state.wallets, ...payload } };

    case TYPES.FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: { ...state.transactions, ...payload } };

    case TYPES.FETCH_LEADS_SUCCESS:
      return { ...state, leads: { ...state.leads, ...payload } };

    case TYPES.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: { ...state.paymentMethods, ...payload },
      };

    case TYPES.FETCH_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: { ...state.subscriptions, ...payload },
      };

    case TYPES.FETCH_TOTAL_REVENUE_SUCCESS:
      return { ...state, totalRevenue: payload };

    case TYPES.FETCH_PAGES_COUNT_SUCCESS:
      return { ...state, pagesCount: payload };

    case TYPES.FETCH_LEADS_COUNT_SUCCESS:
      return { ...state, leadsCount: payload };

    case TYPES.FETCH_USER_AFFILIATES_SUCCESS:
      return { ...state, affiliates: payload };

    case TYPES.SET_DASHBOARD_COUNT:
      return { ...state, [payload.key]: payload.value };

    case TYPES.FETCH_USER_BY_CATEGORY_SUCCESS:
      return { ...state, userByCategoryCount: payload };

    case TYPES.FETCH_WALLET_BALANCE_SUCCESS:
      return { ...state, walletBalanceCount: payload };

    default:
      return state;
  }
};
