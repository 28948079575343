import React from 'react';
import { Skeleton } from 'antd';

const PageLoader = () => (
  <div className="container py-5">
    <div className="row">
      <div className="col-md-4">
        <Skeleton
          active
          loading
          avatar={{
            active: true,
            shape: 'square',
            size: 'large',
          }}
          paragraph={{
            rows: 10,
          }}
          title
        />
      </div>
      <div className="col-md-8">
        <Skeleton
          active
          loading
          paragraph={{
            rows: 15,
          }}
          title
        />
      </div>
    </div>
  </div>
);

export default PageLoader;
