import * as TYPES from './actionTypes';

export const changePassword = payload => ({
  payload,
  type: TYPES.CHANGE_PASSWORD,
});

export const createNewAdminUser = payload => ({
  payload,
  type: TYPES.CREATE_NEW_ADMIN_USER,
});

export const deleteAdminUser = payload => ({
  payload,
  type: TYPES.DELETE_ADMIN_USER,
});

export const fetchAllAdminUsers = payload => ({
  payload,
  type: TYPES.FETCH_ALL_ADMIN_USERS,
});

export const fetchAllAdminUsersSuccessful = payload => ({
  payload,
  type: TYPES.FETCH_ALL_ADMIN_USERS_SUCCESS,
});
