import { all } from 'redux-saga/effects';

import signIn from 'pages/signIn';
import users from 'pages/users';
import settings from 'pages/settings';

export default function* rootSaga() {
  yield all([
    signIn.sagas(),
    users.sagas(),
    settings.sagas(),
  ]);
}
