import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import localforage from 'localforage';
import reduxReset from 'redux-reset';

import createRootReducer from 'components/rootReducer';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: localforage,
};

/**
 * Configures the redux store with its appropriate middle wares and sets its
 * initial state.
 *
 * @function
 * @params {Object} initialState - the initial state of the redux store
 * @return {Object} properly configured redux store
 */
export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

  return {
    ...createStore(
      persistedReducer,
      initialState,
      composeEnhancers(
        applyMiddleware(
          sagaMiddleware
        ),
        reduxReset()
      )
    ),
    runSaga: sagaMiddleware.run,
  };
}
