import { Layout } from 'antd';
import React from 'react';

const { Footer } = Layout;

const year = new Date().getFullYear();

/**
 * @function
 * @summary Render the app-wide footer
 */
const AppFooter = () => (
  <Footer className="app-footer">
    {`Softlink Admin ©${year}`}
  </Footer>
);

export default AppFooter;
