import React from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export const COMPONENT = 'auth';

export const FORM_FIELDS = [
  {
    icon: <UserOutlined />,
    key: 'username',
    rules: [
      {
        message: 'Username cannot be empty and cannot contain spaces.',
        min: 5,
        pattern: /^\S*$/,
        required: true,
      },
    ],
    text: 'Username',
    type: 'text',
  },
  {
    icon: <LockOutlined />,
    key: 'password',
    rules: [
      {
        message: 'Passwords cannot contain spaces.',
        min: 6,
        // pattern: /(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
        pattern: /^\S*$/,
        required: true,
      },
    ],
    text: 'Password',
    type: 'password',
  },
];
