import { COMPONENT } from './constants';

export const FECTH_SHOP_OWNERS = 'FECTH_SHOP_OWNERS'

export const FECTH_SHOP_OWNERS_SUCCESS = 'FECTH_SHOP_OWNERS_SUCCESS'

export const FECTH_PAGES_OWNERS = 'FECTH_PAGES_OWNERS'

export const FECTH_PAGES_OWNERS_SUCCESS = 'FECTH_PAGES_OWNERS_SUCCESS'


export const FETCH_SHOPS_STATS = 'FETCH_SHOPS_STATS'

export const FETCH_PAGES_STATS = 'FETCH_PAGES_STATS'

export const FETCH_SHOPS_STATS_SUCCESS = 'FETCH_SHOPS_STATS_SUCCESS'

export const FETCH_PAGES_STATS_SUCCESS = 'FETCH_PAGES_STATS_SUCCESS'



export const FETCH_USERS = `${COMPONENT}/FETCH_USERS`;

export const SORT_USERS = `${COMPONENT}/SORT_USERS`;

export const FETCH_USERS_SUCCESS = `${COMPONENT}/FETCH_USERS_SUCCESS`;

export const FETCH_USERS_ERROR = `${COMPONENT}/FETCH_USERS_ERROR`;

export const SELECT_SINGLE_USER = `${COMPONENT}/SELECT_SINGLE_USER`;

export const UPDATE_USER = `${COMPONENT}/USER_ACTIONS/UPDATE_USER`;

export const UPDATE_USER_ERROR = `${COMPONENT}/USER_ACTIONS/UPDATE_USER_ERROR`;

export const SEARCH_VALUE = `${COMPONENT}/SEARCH_VALUE`;

export const SEARCH_DATE_VALUE = `${COMPONENT}/SEARCH_DATE_VALUE`;

export const SEARCH_SUBSCRIBED_VALUE = `${COMPONENT}/SEARCH_SUBSCRIBED_VALUE`;

export const FETCH_PAGES = `${COMPONENT}/FETCH_PAGES`;

export const FETCH_PAGES_SUCCESS = `${COMPONENT}/FETCH_PAGES_SUCCESS`;

export const FETCH_DOMAINS_SUCCESS = `${COMPONENT}/FETCH_DOMAINS_SUCCESS`;

export const FETCH_WALLET = `${COMPONENT}/FETCH_WALLET`;

export const FETCH_WALLET_SUCCESS = `${COMPONENT}/FETCH_WALLET_SUCCESS`;

export const FETCH_TRANSACTIONS = `${COMPONENT}/FETCH_TRANSACTIONS`;

export const FETCH_TRANSACTIONS_SUCCESS = `${COMPONENT}/FETCH_TRANSACTIONS_SUCCESS`;

export const FETCH_LEADS = `${COMPONENT}/FETCH_LEADS`;

export const FETCH_LEADS_SUCCESS = `${COMPONENT}/FETCH_LEADS_SUCCESS`;

export const FETCH_DASHBOARD_COUNTS = `${COMPONENT}/FETCH_DASHBOARD_COUNTS`;

export const FETCH_TOTAL_REVENUE_SUCCESS = `${COMPONENT}/FETCH_TOTAL_REVENUE_SUCCESS`;

export const FETCH_PAGES_COUNT_SUCCESS = `${COMPONENT}/FETCH_PAGES_COUNT_SUCCESS`;

export const FETCH_LEADS_COUNT_SUCCESS = `${COMPONENT}/FETCH_LEADS_COUNT_SUCCESS`;

export const APP_ERROR = `${COMPONENT}/APP_ERROR`;

export const FETCH_PAYMENT_METHODS = `${COMPONENT}/FETCH_PAYMENT_METHODS`;

export const FETCH_PAYMENT_METHODS_SUCCESS = `${COMPONENT}/FETCH_PAYMENT_METHODS_SUCCESS`;

export const FETCH_USER_SUBSCRIPTIONS = `${COMPONENT}/FETCH_USER_SUBSCRIPTIONS`;

export const FETCH_USER_SUBSCRIPTIONS_SUCCESS = `${COMPONENT}/FETCH_USER_SUBSCRIPTIONS_SUCCESS`;

export const FETCH_USER_AFFILIATES = `${COMPONENT}/FETCH_USER_AFFILIATES`;

export const FETCH_USER_AFFILIATES_SUCCESS = `${COMPONENT}/FETCH_USER_AFFILIATES_SUCCESS`;

export const UPDATE_USER_SUBSCRIPTION = `${COMPONENT}/UPDATE_USER_SUBSCRIPTION`;

export const UPDATE_USER_SUBSCRIPTION_SUCCESS = `${COMPONENT}/UPDATE_USER_SUBSCRIPTION_SUCCESS`;

export const SET_DASHBOARD_COUNT = `${COMPONENT}/SET_DASHBOARD_COUNT`

export const UPDATE_USER_VERIFICATION_STATUS =  `${COMPONENT}/UPDATE_USER_VERIFICATION_STATUS`;

export const UPDATE_USER_VERIFICATION_STATUS_SUCCESS = `${COMPONENT}/UPDATE_USER_VERIFICATION_STATUS_SUCCESS`;

export const FETCH_WALLET_BALANCE = `${COMPONENT}/FETCH_WALLET_BALANCE`;

export const FETCH_WALLET_BALANCE_SUCCESS = `${COMPONENT}/FETCH_WALLET_BALANCE_SUCCESS`;

export const FETCH_USER_BY_CATEGORY = `${COMPONENT}/FETCH_USER_BY_CATEGORY`;

export const FETCH_USER_BY_CATEGORY_SUCCESS = `${COMPONENT}/FETCH_USER_BY_CATEGORY_SUCCESS`;
