import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

/**
 * @function
 * @summary Renders the Page Helmet to handle page name
 * @param {String} pageDescription - Meta description of the page
 * @param {String} pageTitle - Page title for the Title
 */
const PageHelmet = ({ pageDescription, pageTitle }) => (
  <Helmet defaultTitle="Softlink Admin" titleTemplate="%s - Softlink Admin" defer={false}>
    <meta charSet="utf-8" />
    <meta name="description" content={pageDescription} />
    <title>{pageTitle}</title>
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);

PageHelmet.defaultProps = {
  pageDescription: 'Softlink Admin Portal',
};

PageHelmet.propTypes = {
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
};

export default PageHelmet;
