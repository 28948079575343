import {
  all,
  call,
  takeLeading,
  select,
  put,
  takeEvery
} from 'redux-saga/effects';
import RequestClient from 'config/RequestClient';
import { API_ROUTES } from 'config/Router/constants';
import { tokenSelector } from 'pages/signIn/selectors';
import { isEmpty } from 'lodash';
import * as TYPES from './actionTypes';
import { fetchAllAdminUsersSuccessful } from './actions';

/**
 * Helper function to create a single admin user
 *
 * @param {String} username
 */
function* createSingleAdminUser(username) {
  const token = yield select(tokenSelector);
  try {
    const requestBody = {
      username,
    };

    const headers = { Authorization: `Bearer ${token}` };

    const response = yield call(
      RequestClient.post,
      API_ROUTES.CREATE_NEW_ADMIN,
      requestBody,
      { headers }
    );

    if (response.status === 201) {
      return { response, username };
    }

    return { error: response, username };
  } catch (error) {
    return { error, username };
  }
}

/**
 * @function
 * Handles the creation of new Admin user
 *
 * @param {Object} action - contains all required for the creation process
 */
// eslint-disable-next-line consistent-return
function* registerNewAdminUser(action) {
  const { closeSnackbar, enqueueSnackbar, values } = action.payload;
  const usernames = values.usernames.split(',');

  try {
    const response = yield all(
      usernames.map(username => call(createSingleAdminUser, username))
    );
    const failedAdditions = response.filter(item => item.error);
    const successfulAdditions = response.filter(item => item.response);

    // if there only failed additions
    if (!isEmpty(failedAdditions) && isEmpty(successfulAdditions)) {
      closeSnackbar('createStatus');

      return enqueueSnackbar('Error! No admin user(s) created.', {
        variant: 'error',
      });
    }

    // if there are failed additions and also successful addition
    if (!isEmpty(failedAdditions) && !isEmpty(successfulAdditions)) {
      closeSnackbar('createStatus');

      return enqueueSnackbar(
        `${successfulAdditions.length} admin user(s) created while ${failedAdditions.length} failed.`,
        {
          variant: 'success',
        }
      );
    }

    // if there are only successful addition
    if (isEmpty(failedAdditions) && !isEmpty(successfulAdditions)) {
      closeSnackbar('createStatus');

      return enqueueSnackbar(
        `${successfulAdditions.length} admin user(s) created.`,
        {
          variant: 'success',
        }
      );
    }
  } catch (error) {
    closeSnackbar('createStatus');
    if (error.toString() === 'Error: Network Error') {
      enqueueSnackbar('Please check your network connection and try again', {
        autoHideDuration: 3000,
        variant: 'error',
      });
    } else {
      enqueueSnackbar(error.toString(), {
        variant: 'error',
      });
    }
  }
}

function* getAllAdminUsers(action) {
  const { enqueueSnackbar } = action.payload;
  const token = yield select(tokenSelector);

  try {
    const headers = { Authorization: `Bearer ${token}` };

    const response = yield call(RequestClient.get, API_ROUTES.SIGN_IN, {
      headers,
    });

    if (response.status === 200) {
      const { data } = response.data;
      yield put(fetchAllAdminUsersSuccessful(data));
    } else {
      enqueueSnackbar('Error! Cannot fetch admin users', {
        variant: 'error',
      });
    }
  } catch (error) {
    if (error.toString() === 'Error: Network Error') {
      enqueueSnackbar('Please check your network connection and try again', {
        autoHideDuration: 3000,
        variant: 'error',
      });
    } else {
      enqueueSnackbar(
        'An error has occured with fetching Admin users.',
        {
          variant: 'error',
        }
      );
    }
  }
}

/**
 * ----------------- Watchers -----------------
 */

/**
 * @function
 * Watches for the {@link TYPES.CREATE_NEW_ADMIN_USER CREATE_NEW_ADMIN_USER} action.
 * Triggers request to create an admin user.
 *
 * @return {void}
 */
function* watchNewAdminRegistration() {
  try {
    yield takeLeading(TYPES.CREATE_NEW_ADMIN_USER, registerNewAdminUser);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * @function
 * Watches for the {@link TYPES.CREATE_NEW_ADMIN_USER CREATE_NEW_ADMIN_USER} action.
 * Triggers request to create an admin user.
 *
 * @return {void}
 */
function* watchAdminUsersFetch() {
  try {
    yield takeEvery(TYPES.FETCH_ALL_ADMIN_USERS, getAllAdminUsers);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* () {
  yield all([watchNewAdminRegistration(), watchAdminUsersFetch()]);
}
