import React from "react";
import PropTypes from "prop-types";
import { UserOutlined, LeftOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Menu, Typography, Avatar, Button } from "antd";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { userNameSelector } from "pages/users/selectors";
import { PATHS } from "config/Router/constants";
import { authUserSelector } from "pages/signIn/selectors";
import { HEADER_KEY_MAP, HEADER_DROP_MENU } from "./constants";

const { Header } = Layout;
const { Title } = Typography;

/**
 * @function
 * Renders the Authenticated App Header
 * @param {Object} props
 */
const AppHeader = (props) => {
  const {
    history,
    match: { path },
  } = props;
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const userId = id?.split("_")[1];
  // Selectors
  const user = useSelector((state) => userNameSelector(state, userId));
  /**
   * @function
   * Determine what breadcrumb header to show
   * @param {String} urlPath
   */
  const determinePageBreadCrumb = (urlPath) => {
    switch (urlPath) {
      case PATHS.DASHBOARD:
      case PATHS.USERS:
      case PATHS.SETTINGS: {
        return null;
      }
      case PATHS.SINGLE_USER: {
        return (
          <Button
            className="breadcrumb-button"
            type="text"
            icon={<LeftOutlined />}
            size="small"
            onClick={() => history.push(PATHS.USERS)}
          >
            Users
          </Button>
        );
      }
      case PATHS.SINGLE_USER_PAGE: {
        return (
          <Button
            className="breadcrumb-button"
            type="text"
            icon={<LeftOutlined />}
            size="small"
            onClick={() => history.push(`/users/view/${id}`)}
          >
            {user}
          </Button>
        );
      }
      default: {
        return (
          <Button
            className="breadcrumb-button"
            type="text"
            icon={<LeftOutlined />}
            size="small"
            onClick={() => history.push(PATHS.DASHBOARD)}
          >
            Dashboard
          </Button>
        );
      }
    }
  };

  let titleText = HEADER_KEY_MAP[path];

  // Check if on Single User view and change Page Name
  if (id && user) {
    titleText = user;
  }

  const headerDropMenu = (
    <Menu>
      {HEADER_DROP_MENU.map((item) => {
        const { action, icon, text, key, dispatchAction } = item;

        return (
          <Menu.Item
            key={key}
            icon={icon}
            onClick={
              dispatchAction
                ? () => dispatch(action({ enqueueSnackbar, history }))
                : () => action({ history })
            }
          >
            {text}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Header className="app-header">
      <div>
        {determinePageBreadCrumb(path)}
        <Title className="app-header-title" level={4} ellipsis>
          {titleText}
        </Title>
      </div>

      <div>
        <Dropdown overlay={headerDropMenu} trigger={["click"]}>
          <Avatar
            size="large"
            style={{ backgroundColor: "#001529" }}
            icon={<UserOutlined />}
            src={authUser.photoURL}
          />
        </Dropdown>
      </div>
    </Header>
  );
};

AppHeader.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default compose(withRouter)(AppHeader);
