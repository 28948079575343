export const PATHS = {
  DASHBOARD: '/dashboard',
  HOME: '/',
  SETTINGS: '/settings',
  SIGN_IN: '/sign-in',
  SINGLE_USER: '/users/view/:id',
  SINGLE_USER_PAGE: '/users/view/:id/page/:pageTitle',
  USERS: '/users',
  'SHOPS-SUBSCRIBED': '/shops-subscribed',
  'PAGES-SUBSCRIBED': '/pages-subscribed'
};

export const ALLOWED_PATHS = [
  '/dashboard',
  '/users',
  '/users/view/:id',
  '/users/view/:id/page/:pageTitle',
  '/settings',
  '/shops-subscribed',
  '/pages-subscribed',
];

export const ALLOWED_STRING_PATHS = [
  'dashboard',
  'users',
  'settings',
  'something_else',
  'shops-subscribed',
  'pages-subscribed'
];

export const API_ROUTES = {
  ADD_DEFAULT_USER_ROLE: '/automation/users/roles',
  CHANGE_PASSWORD: '/user/change/password',
  COUNT_ALL_LEADS: '/leads/all/count',
  COUNT_ALL_PAGES: '/pages/all/count',
  CREATE_NEW_ADMIN: '/auth/admin/create',
  GET_WALLET_BALANCE: '/wallets/balance',
  GET_USER_BY_CATEGORY: '/users/category/',
  DELETE_TRANSACTION: '/transaction/',
  GET_ALL_DOMAINS: '/domains',
  GET_ALL_PAGES: '/pages',
  GET_PAGE: '/page/',
  GET_PAYMENT_METHODS: '/payment-methods/',
  GET_TOTAL_REVENUE: '/accounting/revenue',
  GET_OCP_COUNT: '/ocp/count/admin',
  GET_PRODUCT_COUNT: '/products/count/admin',
  GET_MEMBERSHIP_COUNT: '/memberships/count/admin',
  GET_SHOP_COUNT: '/shops/count/admin',
  GET_EMAIL_COUNT: '/mails/count/admin',
  GET_TRANSACTION_BY_ID: '/transaction/',
  GET_TRANSACTION_BY_ID_OR_REF: '/transaction?query=',
  GET_USERS: '/users',
  GET_USERS_LEADS: '/leads/',
  GET_USER_DOMAINS: '/domains/',
  GET_USER_PAGES: '/pages/',
  GET_USER_TRANSACTIONS: '/transactions/',
  GET_USER_WALLET: '/wallet/',
  GET_USER_SUBSCRIPTIONS: '/subscriptions/',
  SIGN_IN: '/auth/admin/signin',
  SINGLE_LEAD: '/lead/',
  SINGLE_USER: '/user/',
  GET_USER_AFFILIATES: '/affiliates/',
  UPDATE_USER_VERIFICATION_STATUS: '/user/verify/',
  UPDATE_USER_SUBSCRIPTION: '/user/subscription/'
};
