import { combineReducers } from 'redux';
import signIn from 'pages/signIn';
import users from 'pages/users';
import settings from 'pages/settings';

export default () => combineReducers({
  app: users.reducers,
  auth: signIn.reducers,
  settings: settings.reducers,
});
