import { createSelector } from 'reselect';
import { isEqual, groupBy, compact } from 'lodash';
import moment from 'moment';
import { SUBSCRIPTION_PLANS } from 'pages/Dashboard/constants';
import { COMPONENT, MONTHS_OF_THE_YEAR } from './constants';

export const usersListSelector = state => state[COMPONENT].users.users;

export const totalUsersSelector = state =>  state[COMPONENT].users;

export const totalUsersFoundSelector = state =>  state[COMPONENT].users.found;

export const totalShopSelector = state =>  state[COMPONENT].shopCount;

export const totalMembershipSelector = state =>  state[COMPONENT].membershipCount;

export const totalDigitalSelector = state =>  state[COMPONENT].digitalProductsCount;

export const totalPhysicalSelector = state =>  state[COMPONENT].physicalProductsCount;

export const totalWalletBalanceSelector = state =>  state[COMPONENT].walletBalanceCount;

export const totalUserByCategorySelector = state =>  state[COMPONENT].userByCategoryCount;

export const totalOcpSelector = state =>  state[COMPONENT].ocpCount;

export const totalEmailSelector = state =>  state[COMPONENT].emailCount;

export const pagesListSelector = state => Object.values(state[COMPONENT].pages);

export const totalRevenueSelector = state => state[COMPONENT].totalRevenue;

export const pagesCountSelector = state => state[COMPONENT].pagesCount;

export const leadsCountSelector = state => state[COMPONENT].leadsCount;

export const pagesListByUserSelector = (state, username) => state[COMPONENT].pages[username];

export const userWalletSelector = (state, username) => state[COMPONENT].wallets[username];

export const userTransactionsSelector = (state, username) => state[COMPONENT].transactions[username];

export const userLeadsSelector = (state, username) => state[COMPONENT].leads[username];

export const userPaymentMethodsSelector = (state, username) => state[COMPONENT].paymentMethods[username];

export const userSubscriptionsSelector = (state, username) => state[COMPONENT].subscriptions[username];

export const userAffiliatesSelector = (state, username) => state[COMPONENT].affiliates[username];

export const userByIdSelector = (state, id) => {
  const list = usersListSelector(state);
  const user = list?.reduce((acc, singleUser) => {
    const { _id } = singleUser;
    if (isEqual(id, _id)) {
      return { ...singleUser };
    }
    return acc;
  }, {});
  return user;
};

export const userNameSelector = createSelector(
  (state, id) => usersListSelector(state)?.reduce((acc, user) => {
    if (user._id.includes(id)) {
      return { ...user };
    }
    return acc;
  }, {}),
  user => {
    if (user) {
      const { firstName, lastName } = user;
      return `${firstName} ${lastName}`;
    }
    return {};
  }
);

export const singleUserSelector = (state, id) => usersListSelector(state)?.reduce((acc, user) => {
  if (user._id.includes(id)) {
    return { ...user };
  }
  return acc;
}, {});

export const searchValueSelector = state => state[COMPONENT].search;

export const sortUsersSelector = state => state[COMPONENT].sortUsers;

export const searchDateValueSelector = state => state[COMPONENT].dateSearch;

export const searchFilterValueSelector = state => state[COMPONENT].subscribedSearch;

export const usersSearchResultSelector = createSelector(
  state => ({
    list: usersListSelector(state),
    value: searchValueSelector(state),
  }),
  dataObject => {
    const { list, value } = dataObject;
    const users = list?.filter(user => {
      const { username, firstName, lastName, email } = user;
      if (
        username?.toLowerCase().includes(value)
        || firstName?.toLowerCase().includes(value)
        || lastName?.toLowerCase().includes(value)
        || email?.toLowerCase().includes(value)
      ) {
        return true;
      }
      return false;
    });

    if (users) {
      return users;
    }

    return [];
  }
);

export const subscriptionDataSelector = createSelector(
  state => ({
    users: usersListSelector(state),
    year: searchValueSelector(state),
  }),
  dataObject => {
    const { users, year } = dataObject;
    if (users) {
      const subscriptionMetrics = users.map(user => {
        const { subscriptionPackage, subscriptionDate } = user;
        if (subscriptionDate) {
          const subscriptionYear = moment(subscriptionDate).format('YYYY');
          const subscriptionMonth = moment(subscriptionDate).format('MMM');
          return {
            month: subscriptionMonth,
            plan: subscriptionPackage,
            year: subscriptionYear,
          };
        }
      });

      const filteredSubscriptionMetrics = compact(subscriptionMetrics)?.filter(
        metric => metric.year === year,
      );
      const dataByPlan = groupBy(
        filteredSubscriptionMetrics,
        data => data?.plan
      );
      const subcriptionGraphData = Object.keys(dataByPlan).map(key => {
        const planData = dataByPlan[key];
        const planDataByMonth = groupBy(planData, data => data.month);

        const graphData = Object.entries(planDataByMonth)?.reduce(
          (acc, monthKey, values) => {
            const month = monthKey[0];
            const data = monthKey[1];
            acc.push({ x: month, y: data.length });
            return acc;
          },
          [],
        );

        graphData.sort(
          (a, b) => MONTHS_OF_THE_YEAR.indexOf(a.x) - MONTHS_OF_THE_YEAR.indexOf(b.x)
        );

        return { data: graphData, key };
      });

      const subcriptionGraphDataByPlan = SUBSCRIPTION_PLANS?.reduce(
        (acc, key) => {
          const data = subcriptionGraphData.filter(
            item => item.key.toLowerCase() === key.value.toLowerCase()
          );
          if (data) {
            acc[key.value] = data;
            return acc;
          }
        },
        {},
      );
      return subcriptionGraphDataByPlan;
    }
    return false;
  }
);

export const searchDateResultsSelector = createSelector(
  (state, dataSource) => ({
    dataSource,
    value: searchDateValueSelector(state),
  }),
  dataObject => {
    const { value, dataSource } = dataObject;
    if (value) {
      const yesterday = moment().endOf('day').subtract(1, 'd');
      const startDate = moment().startOf('day').subtract(value, 'd');

      const result = dataSource?.filter(item => moment(item.createdAt).isBetween(startDate, yesterday),
    );
      return result || [];
    }

    return [];
  }
);

export const searchFilterResultsSelector = createSelector(
  (state, dataSource) => ({
    dataSource,
    value: searchFilterValueSelector(state),
  }),
  dataObject => {
    const { value, dataSource } = dataObject;
    if (value) {
      const result = dataSource?.filter(item => String(item.isSubscribed) === value);
      return result || [];
    }

    return [];
  }
);

export const singleUserSearchDateResultsSelector = createSelector(
  (state, dataSource, type) => ({
    leads: dataSource.leads,
    paymentMethods: dataSource.paymentMethods,
    subscriptions: dataSource.subscriptions,
    transactions: dataSource.transactions,
    affiliates: dataSource.affiliates,
    type,
    value: searchDateValueSelector(state),
  }),
  dataObject => {
    const {
      value, leads, transactions, type,
      paymentMethods, subscriptions, affiliates
    } = dataObject;
    const yesterday = moment().endOf('day').subtract(1, 'd');
    const startDate = moment().startOf('day').subtract(value, 'd');

    if (value) {
      if (type === 'leads') {
        const result = leads?.filter(item => moment(item.createdAt).isBetween(startDate, yesterday),
        );
        return { leads: result || [], paymentMethods, transactions, subscriptions, affiliates };
      }

      if (type === 'paymentMethods') {
        const result = paymentMethods.filter(item => moment(item.createdAt).isBetween(startDate, yesterday)
        );
        return { leads, paymentMethods: result || [], transactions,subscriptions, affiliates };
      }

      if (type === 'subscriptions') {
        const result = subscriptions.filter(item => moment(item.createdAt).isBetween(startDate, yesterday));
        return {leads, paymentMethods, transactions, suscriptions: result || [], affiliates}
      }

      if (type === 'affiliates') {
        const result = affiliates.filter(item => moment(item.createdAt).isBetween(startDate, yesterday));
        return {leads, paymentMethods,transactions,subscriptions, affiliates: result || []}
      }

      const result = transactions?.filter(item => moment(item.createdAt).isBetween(startDate, yesterday),
      );
      return { leads, paymentMethods, transactions: result || [], subscriptions, affiliates };
    }

    return { leads: [], paymentMethods: [], transactions: [], subscriptions: [], affiliates:[] };
  }
);

export const singleUserSearchResultSelector = createSelector(
  (state, dataSource, type) => ({
    leads: dataSource.leads,
    transactions: dataSource.transactions,
    paymentMethods: dataSource.paymentMethods,
    subscriptions: dataSource.subscriptions,
    affiliates: dataSource.affiliates,
    type,
    value: searchValueSelector(state),
  }),
  dataObject => {
    const {
      leads, transactions, value, type,
      paymentMethods, subscriptions, affiliates
    } = dataObject;

    if (value) {
      if (type === 'leads') {
        const filteredLeads = leads?.filter(lead => {
          const { name, email } = lead;
          if (name.includes(value) || email.includes(value)) {
            return true;
          }
          return false;
        });

        return { leads: filteredLeads || [], transactions, paymentMethods, subscriptions, affiliates };
      }

      if (type === 'paymentMethods') {
        const filteredPaymentMethods = paymentMethods?.filter(paymentMethod => {
          const { card } = paymentMethod
          if (card?.type?.includes(value) || card?.last4digits?.includes(value)) {
            return true;
          }
          return false;
        });
        return {leads, transactions, paymentMethods: filteredPaymentMethods || [], subscriptions, affiliates}
      }

      if (type === 'subscriptions') {
        const filteredSubscriptions = subscriptions?.filter(subscription => {
          const { subscriptionPackage } = subscription
          if (subscriptionPackage.includes(value)) {
            return true;
          }
          return false;
        });
        return {leads, transactions, paymentMethods, subscriptions: filteredSubscriptions || [], affiliates}
      }

       if (type === 'affiliates') {
        const filteredAffiliates = affiliates?.filter(affiliate => {
          const { firstName,lastName,email,username, phoneNumber,subscriptionPackage } = affiliate
          const arr = [firstName, lastName, email, username, phoneNumber, subscriptionPackage]
          if (arr.includes(value)) {
            return true;
          }
          return false;
        });
        return {leads, transactions, paymentMethods, subscriptions, affiliates: filteredAffiliates || []}
      }

      const filteredTransactions = transactions?.filter(transaction => {
        const {
          paymentReference,
          customer: { name },
        } = transaction;
        if (paymentReference.includes(value) || name.includes(value)) {
          return true;
        }
        return false;
      });

      return { leads, transactions: filteredTransactions || [], subscriptions, paymentMethods, affiliates };
    }

    return { leads: [], transactions: [], subscriptions: [], paymentMethods: [], affiliates: [] };
  }
);
