import { COMPONENT } from './constants';

export const CHANGE_PASSWORD = `${COMPONENT}/CHANGE_PASSWORD`;

export const CREATE_NEW_ADMIN_USER = `${COMPONENT}/CREATE_NEW_ADMIN_USER`;

export const DELETE_ADMIN_USER = `${COMPONENT}/DELETE_ADMIN_USER`;

export const FETCH_ALL_ADMIN_USERS = `${COMPONENT}/FETCH_ALL_ADMIN_USERS`;

export const FETCH_ALL_ADMIN_USERS_SUCCESS = `${COMPONENT}/FETCH_ALL_ADMIN_USERS_SUCCESS`;
