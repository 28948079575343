import React, {
  useState, useEffect, Suspense, lazy
} from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { ALLOWED_STRING_PATHS, PATHS } from 'config/Router/constants';
import withAuthorization from 'config/WithAuthorization';
import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import PageLoader from '../PageLoader';

const { Content, Sider } = Layout;

const Dashboard = lazy(() => import('pages/Dashboard'));
const UsersPageSwitch = lazy(() => import('pages/users/components'));
const SettingsPage = lazy(() => import('pages/settings/components'));
const ShopOwnersPage = lazy(() => import('pages/ShopOwners'));
const PageOwnersPage = lazy(() => import('pages/PageOwners'));

const ROUTE_COMPONENTS = {
  dashboard: Dashboard,
  settings: SettingsPage,
  users: UsersPageSwitch,
  'pages-subscribed': PageOwnersPage,
  'shops-subscribed': ShopOwnersPage,
};

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const screenHeight = window.screen.height;

  // Change the status of the collapsible sidebar
  useEffect(() => {
    if (screenHeight <= 767) {
      setCollapsed(true);
    }
  }, [screenHeight]);

  /**
     * Defines the routes for the App.
     *
     * @function
     * @returns {Array} - Array or allowed Paths and thier components
     */
  const ALLOWED_ROUTES = ALLOWED_STRING_PATHS.filter(entity => entity != null).map(item => {
    const values = { component: ROUTE_COMPONENTS[item], path: PATHS[item.toUpperCase()] };
    return values;
  });

  return (
    <Suspense fallback={<PageLoader />}>
      <Layout className="app-container">
        <Sider
          collapsed={collapsed}
          trigger={null}
          collapsible
          onCollapse={onCollapse}
          collapsedWidth={50}
          className="app-sider"
          width="200"
        >
          <AppMenu menuCollapsed={collapsed} menuTrigger={onCollapse} />
        </Sider>
        <Layout>
          <AppHeader />
          <Content className="app-content">
            <Switch>
              {
                ALLOWED_ROUTES.map(routePath => {
                  const { component, path } = routePath;
                  if (component == null) { return false; }
                  return <Route key={path} path={path} component={component} />;
                })
              }
            </Switch>
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </Suspense>
  );
};

export default withAuthorization()(AppLayout);