import * as TYPES from './actionTypes';

const initialState = {
  authorizations: [],
  error: false,
  isAuthenticated: false,
  isLoggedIn: false,
  token: false,
  user: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case TYPES.SIGN_IN_SUCCESS:
    return { ...state, ...payload };

  case TYPES.SIGN_IN_ERROR:
    return { ...state, error: payload };

  case TYPES.SIGN_OUT_SUCCESS:
    return { ...state };
  default:
    return state;
  }
};
