import React from 'react';
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd'
export const COMPONENT = 'dashboard';

export const generateDashboardStats = ({
  totalUsers, pagesCount, leadsCount, totalRevenue, shopCount,
  membershipCount, digitalCount, physicalCount, ocpCount, emailCount, pageStats, shopStats
}) => [
  {
    currency: false,
    currentValue: 0,
    key: 'ActiveUsers',
    percentage: 0,
    showCurrentValue: false,
    title: 'Registered Users',
    totalValue: parseFloat(totalUsers?.total || 0),
    info: <Tooltip placement="top" title="No of Registered Users">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'subscribedUsers',
    showCurrentValue: false,
    title: 'Subscribed Users',
    totalValue: parseFloat(totalUsers?.subscribed || 0),
    info: <Tooltip placement="top" title="No of Subscribed Users">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'activatedUsers',
    showCurrentValue: false,
    title: 'Activated users',
    totalValue: parseFloat(totalUsers?.activated || 0),
    info: <Tooltip placement="top" title="Activated users are users with at least one published page">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: true,
    currentValue: 0,
    key: 'TotalRevenue',
    percentage: 0,
    showCurrentValue: false,
    title: 'Total Revenue Generated',
    totalValue: parseFloat(totalUsers?.revenues?.totalRevenue || 0),
    info: <Tooltip placement="top" title="No of Total Revenue Generated">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalPages',
    showCurrentValue: false,
    title: 'Total Pages',
    totalValue: parseFloat(pageStats?.pages || 0),
    ownedBy: pageStats?.pagesOwners || 0,
    subscribedOwners: pageStats?.subscribedOnwers?.subscribed || 0,
    info: <Tooltip placement="top" title="Total Number of Pages">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'publishedPage',
    showCurrentValue: false,
    title: 'Total Published Page',
    totalValue: parseFloat(totalUsers?.pages?.published || 0),
    info: <Tooltip placement="top" title="No of Published Page">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalLeads',
    showCurrentValue: false,
    title: 'Total Leads',
    totalValue: parseFloat(totalUsers?.leads || 0),
    info: <Tooltip placement="top" title="No of Total Leads">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalEmail',
    showCurrentValue: false,
    title: 'Total Email/SMS',
    totalValue: parseFloat(totalUsers?.mails?.count || 0),
    info: <Tooltip placement="top" title="No of Total Email/SMS">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalAffiliateLinks',
    showCurrentValue: false,
    title: 'Total Affiliate Links',
    totalValue: parseFloat(totalUsers?.affiliates?.count || 0),
    info: <Tooltip placement="top" title="No of Affiliate Links">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalStores',
    showCurrentValue: false,
    title: 'Total Stores',
    totalValue: parseFloat(shopStats?.count || 0),
    ownedBy: shopStats?.shopOwners || 0,
    subscribedOwners: shopStats?.subscribedOnwers?.subscribed || 0,
    info: <Tooltip placement="top" title="No of Total Stores">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalMemberships',
    showCurrentValue: false,
    title: 'Total Memberships',
    totalValue: parseFloat(totalUsers?.memberships?.count || 0),
    info: <Tooltip placement="top" title="No of Total Memberships">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalDigital',
    showCurrentValue: false,
    title: 'Total Digital Products',
    totalValue: parseFloat(totalUsers?.products?.digital || 0),
    info: <Tooltip placement="top" title="No of Total Digital Products">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalPhysical',
    showCurrentValue: false,
    title: 'Total Physical Products',
    totalValue: parseFloat(totalUsers?.products?.physical || 0),
    info: <Tooltip placement="top" title="No of Total Physical Products">
      <InfoCircleOutlined />
      </Tooltip>
  },
  {
    currency: false,
    currentValue: 0,
    key: 'TotalOCPs',
    showCurrentValue: false,
    title: 'Total One Click Checkout Products',
    totalValue: parseFloat(totalUsers?.ocp?.count || 0),
    info: <Tooltip placement="top" title="No of OCP">
      <InfoCircleOutlined />
      </Tooltip>
  }
];

export const TOP_USERS_FILTER = [
  { key: 'spent', text: 'Spent', value: 0 },
  { key: 'totalSession', text: 'Session', value: 0 },
];

export const SUBSCRIPTION_PLANS = [
  { value: "pro", text: "Starter" }, 
  { value: "business", text: "Professional" }, 
  { value: "enterprise", text: "Growth" }, 
];

export const SUBSCRIPTION_PLANS_COLOR = {
  pro: '#a4e4b8',
  business: '#6298E6',
  enterprise: '#FFD200',
};
